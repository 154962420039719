<template>
  <div class='page-box'>
    <div class='btn-box'>
      <a-button type='primary' @click='addRecord'>添加通知</a-button>
    </div>
    <a-table :loading='loading' :rowKey="record => record.levelId" :columns='columns' :data-source="tabldeData" :pagination="false" bordered>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click='editRecord(record)'>编辑</a-button>
        <a-popconfirm placement="topRight" title="确定要删除？" ok-text="确定" cancel-text="取消" @confirm="()=>confirmDelete(record)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal v-model='showModal' :width="800" :title='isAdd?"添加通知":"编辑通知"' :confirm-loading='confirmLoading' @ok='handleSubmit' @cancel='handelCancel'>
      <a-form-model ref='formRef' :model="form" :rules="rules" :wrapper-col="{span:20}" :label-col="{span:4}">
        <a-form-model-item label='标题' prop='title'>
          <a-input v-model="form.title" placeholder='请填写通知标题'></a-input>
        </a-form-model-item>
        <a-form-model-item label='通知内容' prop='content'>
          <div class="rich-text" ref="richTextRef"></div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { checkInteger } from '@/utils/validator'
import { shop } from '@/api'
import E from 'wangeditor'
export default {
  name: 'PageTeamLevelManagement',
  data () {
    return {
      levelId: '',
      isAdd: false,
      form: {
        relationType: 2
      },
      editor: null,
      showModal: false,
      confirmLoading: false,
      loading: false,
      editorContent: '',
      rules: {
        title: [{ required: true, message: '请输入', trigger: 'change' }],
        content: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      columns: [
        {
          title: '标题',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tabldeData: [],
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    createEditor () {
      this.editor = new E(this.$refs.richTextRef)
      this.editor.customConfig = {
        onchange: (html) => {
          this.form.upGradeDesc = html
        },
        menus: ['head', 'bold', 'fontSize', 'justify', 'undo', 'redo'],
        showLinkImg: false,
      }
      this.editor.create()
    },
    async getData () {
      this.loading = true
      const { data, msg, code } = await shop.getShopNoticeList({ relationType: 2 })
      this.loading = false
      if (code === '00000') {
        this.tabldeData = data
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async confirmDelete (record) {
      const { msg, code } = await shop.deleteShopNotice({
        id: record.shopNoticeId,
      })
      if (code === '00000') {
        this.$message.success('操作成功')
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    handleSubmit () {
      this.form.content = this.editor.txt.html()
      this.$refs.formRef.validate((v) => {
        if (v) {
          this.submit()
        }
      })
    },
    async submit () {
      this.confirmLoading = true
      this.form.relationType = 2
      const { msg, code } = await shop.saveShopNotice(this.form)
      this.confirmLoading = false
      if (code === '00000') {
        this.$message.success('操作成功')
        this.handelCancel()
        this.showModal = false
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    handelCancel () {
      this.$refs.formRef.resetFields()
      this.form = {}
      if (this.editor) this.editor.txt.html('')
    },
    async addRecord () {
      this.isAdd = true
      this.showModal = true
      if (!this.editor) {
        await this.$nextTick()
        this.createEditor()
      }
    },
    async editRecord (record) {
      this.isAdd = false
      this.form = Object.assign({}, record)
      this.showModal = true
      if (!this.editor) {
        await this.$nextTick()
        this.createEditor()
      }
      this.form.content = record.content
      if (this.editor) this.editor.txt.html(record.content)
    },
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  margin: 12px 0;
}
</style>
